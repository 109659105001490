import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Link as LinkExt,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { uniqueId } from "lodash"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faMapMarkedAlt,
} from "@fortawesome/pro-regular-svg-icons"
import Slider from "react-slick"

import { VideoPlayer } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import ArrowLeft from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/ArrowRight"
import ArrowRight from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/ArrowLeft"
import {
  ServicesTreatmentsPagesHero,
  Seo,
  SpecialistsDirService,
} from "../../../components/elements"

const Service = ({ data, pageContext }) => {
  const page = data.datoCmsWvumService
  const location = page.locations.map((location) => location.id)
  const heroimg = data.datoCmsAsset.gatsbyImageData

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  return (
    <Layout>
      {location.includes("DatoCmsInstitute-3541459-en") ? (
        <>
          <Seo title={page.title} />

          <ServicesTreatmentsPagesHero
            page={page}
            heroimg={heroimg}
            eyebrow="Treatments & Services:"
          >
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator=" / "
              crumbLabel={page.title}
            />
          </ServicesTreatmentsPagesHero>

          <Container width="full">
            <Stack
              direction={["column", "column", "row"]}
              spacing={12}
              py={[9, 9, 12, 16]}
            >
              <Main width={["full", "full", "2 / 3"]}>
                {/* Content */}
                <Flex flexWrap="wrap">
                  <Box width="full">
                    {page.video && (
                      <Box mb={9}>
                        <VideoPlayer url={page.video.url} />
                      </Box>
                    )}

                    <Box
                      dangerouslySetInnerHTML={{
                        __html: page.descriptionNode.childMarkdownRemark.html,
                      }}
                    />

                    {page.image.length > 0 ? (
                      <Box width="full" my={6}>
                        <Slider {...settings}>
                          {page.image.map(({ gatsbyImageData }) => (
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt={page.title}
                            />
                          ))}
                        </Slider>
                      </Box>
                    ) : null}

                    {page.locations && (
                      <Box width="full">
                        <Box as="h2">Locations</Box>

                        <Accordion width="full" allowMultiple>
                          {page.locations.map(
                            ({
                              clinicName,
                              centerName,
                              hospitalName,
                              googleMapUrl,
                              websiteUrl,
                              address,
                              phoneNumbers,
                            }) => (
                              <AccordionItem key={uniqueId("location_id_")}>
                                {/* Hospitals */}
                                {hospitalName && (
                                  <AccordionButton>
                                    <Box
                                      as="h3"
                                      flex="1"
                                      textAlign="left"
                                      mb={0}
                                    >
                                      {hospitalName}
                                    </Box>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                  </AccordionButton>
                                )}

                                {/* Centers */}
                                {centerName && (
                                  <AccordionButton>
                                    <Box
                                      as="h3"
                                      flex="1"
                                      textAlign="left"
                                      mb={0}
                                    >
                                      {centerName}
                                    </Box>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                  </AccordionButton>
                                )}

                                {/* Clinics */}
                                {clinicName && (
                                  <AccordionButton>
                                    <Box
                                      as="h3"
                                      flex="1"
                                      textAlign="left"
                                      mb={0}
                                    >
                                      {clinicName}
                                    </Box>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                  </AccordionButton>
                                )}

                                <AccordionPanel bg="lightblue" pt={6}>
                                  <SimpleGrid columns={[1, 1, 2]} spacing={6}>
                                    <Box>
                                      {address.map(
                                        ({
                                          streetAddress,
                                          addressLine2,
                                          city,
                                          state,
                                          zipCode,
                                        }) => (
                                          <Box
                                            key={uniqueId(
                                              "hospital_address_id_"
                                            )}
                                          >
                                            <p>
                                              <strong>Address:</strong>
                                              <br />
                                              {streetAddress && streetAddress}
                                              {addressLine2 && <br />}
                                              {addressLine2 && addressLine2}
                                              <br />
                                              {city && city}, {state && state}{" "}
                                              {zipCode && zipCode}
                                            </p>
                                          </Box>
                                        )
                                      )}
                                      <LinkExt href={googleMapUrl}>
                                        <p>
                                          <strong>
                                            Map it{" "}
                                            <FontAwesomeIcon
                                              icon={faMapMarkedAlt}
                                            />
                                          </strong>
                                        </p>
                                      </LinkExt>
                                    </Box>

                                    <Box>
                                      {phoneNumbers.length > 0 && (
                                        <p>
                                          <strong>Contact Info:</strong>
                                          <br />
                                          {phoneNumbers.map(
                                            ({
                                              phoneNumber,
                                              phoneNumberTitle,
                                            }) => (
                                              <Box
                                                as="span"
                                                key={uniqueId(
                                                  "hospital_phones_id_"
                                                )}
                                              >
                                                {phoneNumberTitle &&
                                                  phoneNumberTitle}
                                                :{" "}
                                                {phoneNumber && (
                                                  <a
                                                    href={`tel:${phoneNumber}`}
                                                  >
                                                    {phoneNumber}
                                                  </a>
                                                )}
                                                <br />
                                              </Box>
                                            )
                                          )}
                                        </p>
                                      )}

                                      {websiteUrl && (
                                        <p>
                                          <strong>Website:</strong>{" "}
                                          <LinkExt href={websiteUrl}>
                                            {websiteUrl}
                                          </LinkExt>
                                        </p>
                                      )}
                                    </Box>
                                  </SimpleGrid>
                                </AccordionPanel>
                              </AccordionItem>
                            )
                          )}
                        </Accordion>
                      </Box>
                    )}
                  </Box>
                </Flex>
              </Main>

              <Box
                as="aside"
                id="sidebar"
                bg="lightgray"
                width={["full", "full", 1 / 3]}
              >
                <Box p={[3, 3, 6]}>
                  {page.findADocSpecialtyId && (
                    <SpecialistsDirService id={page.findADocSpecialtyId} />
                  )}
                </Box>
              </Box>
            </Stack>
          </Container>
        </>
      ) : (
        <Container>
          <Box width="full" height={96}>
            <Alert status="warning" mt={24}>
              <AlertIcon />
              <AlertTitle mr={2}>Page not found:</AlertTitle>
              <AlertDescription>
                This service is not available at this location.
              </AlertDescription>
            </Alert>
          </Box>
        </Container>
      )}
    </Layout>
  )
}

export default Service

export const query = graphql`
  query serviceQuery($slug: String!) {
    datoCmsWvumService(slug: { eq: $slug }) {
      id
      findADocSpecialtyId
      slug
      title
      locations {
        ... on DatoCmsClinic {
          id
          clinicName
          googleMapUrl
          websiteUrl
          address {
            addressLine2
            city
            state
            streetAddress
            zipCode
            id
          }
          phoneNumbers {
            phoneNumber
            phoneNumberTitle
          }
        }
        ... on DatoCmsCenter {
          id
          centerName
          googleMapUrl
          websiteUrl
          address {
            addressLine2
            city
            state
            streetAddress
            zipCode
            id
          }
          phoneNumbers {
            phoneNumber
            phoneNumberTitle
          }
        }
        ... on DatoCmsHospital {
          id
          hospitalName
          googleMapUrl
          websiteUrl
          address {
            addressLine2
            city
            state
            streetAddress
            zipCode
            id
          }
          phoneNumbers {
            phoneNumber
            phoneNumberTitle
          }
        }
        ... on DatoCmsInstitute {
          id
          instituteName
          googleMapUrl
          websiteUrl
          address {
            addressLine2
            city
            state
            streetAddress
            zipCode
            id
          }
          phoneNumbers {
            phoneNumber
            phoneNumberTitle
          }
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData
      }
      providersNode {
        childMarkdownRemark {
          html
        }
      }
      meta {
        status
      }
      video {
        url
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
    datoCmsAsset(id: { eq: "DatoCmsAsset-15668978" }) {
      id
      gatsbyImageData
    }
  }
`
